import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import { default as DefaultGridParagraph } from "@mostlycode/ui/dist/Paragraphs/GridParagraph"
import Content from "components/Content"
import ResponsiveImage from "components/ResponsiveImage"

function getTheme(colorTheme, colors) {
  switch (colorTheme) {
    case "Blue":
      return `
        background: ${colors.primary};
        color: ${colors.white};
      `
    case "Red":
      return `
        background: ${colors.red};
        color: ${colors.white};
      `
    case "Black":
      return `
        background: ${colors.blackLight};
        color: ${colors.white};
      `
    case "White":
    default:
      return `
        background: ${colors.white};
        color: ${colors.blackLight};
      `
  }
}

const StyledParagraphImage = styled(DefaultGridParagraph)`
  padding: 4rem 0;
  max-width: 120rem;
  margin: 0 auto;
  ${(props) => getTheme(props.color_theme, props.theme.colors)};
  background: transparent;
`

const StyledParagraph = styled.article`
  grid-area: paragraph;
  padding: 2rem;
  display: flex;
  align-items: center;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}
`

const StyledImageContainer = styled.div`
  grid-area: image;

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    align-items: center;
  `}
`

const StyledWrapper = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 80%;
    margin: 0 auto;
  `}
`

const gridPhone = `"image" "paragraph"`
const gridTabletPortrait = `"image image paragraph paragraph paragraph"`

function ParagraphImage({ image, content, image_position, ...props }) {
  return (
    <StyledParagraphImage
      flipTabletLandscape={image_position}
      gridPhone={gridPhone}
      gridTabletLandscape={gridTabletPortrait}
      useFractions
      {...props}
    >
      <StyledParagraph>
        <StyledWrapper>
          <Content content={content} />
        </StyledWrapper>
      </StyledParagraph>
      {image && (
        <StyledImageContainer>
          <ResponsiveImage img={image} />
        </StyledImageContainer>
      )}
    </StyledParagraphImage>
  )
}

export default ParagraphImage

ParagraphImage.defaultProps = {}
ParagraphImage.propTypes = {
  content: PropTypes.object.isRequired,
  image: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
  }),
}
