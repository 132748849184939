import React from "react"
import PropTypes from "prop-types"
import { formatDistanceStrict } from "date-fns"
import { da } from "date-fns/locale"

function Duration({ start, end, ...props }) {
  const now = new Date()
  const duration = formatDistanceStrict(now, new Date(end), {
    unit: "day",
    locale: da,
  })
  return <span>{duration}</span>
}

export default Duration

Duration.defaultProps = {}

Duration.propTypes = {
  end: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
}
