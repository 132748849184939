import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Card } from "@mostlycode/ui"
import FormField from "components/FormField"
import Button from "components/Button"
import Input from "components/Input"
import Loading from "components/Loading"
import { SiteContext } from "context/SiteContext"
import { setUserId, setAuthenticated, toggleLoading } from "actions/site"
import { useAuth } from "hooks/useAuth"
import { login, verify } from "services/auth"
import { navigate } from "gatsby"

const StyledLogin = styled(Card)`
  border-radius: 1rem;
  position: relative;

  h3 {
    line-height: 1.5;
    // margin: 0;
    text-align: center;
    margin-top: 0;
  }

  button {
    margin-top: 2rem;
    display: block;
    width: 100%;
  }
`

function Login({ register, children, ...props }) {
  const {
    state: { userId },
    dispatch,
  } = useContext(SiteContext)
  const [email, setEmail] = useState()
  const [otp, setOtp] = useState()
  const [verificationState, setVerificationState] = useState("login")
  const authenticated = useAuth()

  async function onLogin() {
    try {
      const response = await login(email)
      if (response.data.status === "OK") {
        setVerificationState("verify")
        dispatch(toggleLoading(false))
        await dispatch(setUserId(response.data.body.id))
      }
    } catch (error) {
      dispatch(toggleLoading(false))
      console.log(error)
    }
  }

  async function onVerify() {
    try {
      const response = await verify(userId, otp)
      if (response.data.status === "OK") {
        dispatch(toggleLoading(false))
        dispatch(setAuthenticated(true))
      }
    } catch (error) {
      dispatch(toggleLoading(false))
      console.log(error)
    }
  }

  function onLoginHandler(e) {
    e.preventDefault()
    dispatch(toggleLoading(true))
    onLogin()
  }

  function onVerifyHandler(e) {
    e.preventDefault()
    dispatch(toggleLoading(true))
    onVerify()
  }

  useEffect(() => {
    if (authenticated) navigate("/app")
  })

  return (
    <StyledLogin {...props}>
      <Loading />
      {verificationState === "login" && (
        <div>
          <h3>Log ind på partnerprofil</h3>
          <form onSubmit={onLoginHandler}>
            <FormField label="Indtast email">
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </FormField>
            <Button type="submit" variant="primary">
              {"Log ind"}
            </Button>
          </form>
        </div>
      )}
      {verificationState === "verify" && (
        <div>
          <h3>indtast engangs-kodeord</h3>
          <form onSubmit={onVerifyHandler}>
            <FormField label="Indtast kode">
              <Input
                type="number"
                maxLength={6}
                onChange={(e) => setOtp(e.target.value)}
              />
            </FormField>
            <Button type="submit" variant="primary">
              OK
            </Button>
          </form>
        </div>
      )}
    </StyledLogin>
  )
}

export default Login

Login.defaultProps = {}
Login.propTypes = {}
