import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Blogpost from "./"

const StyledBlogpostRepeater = styled.section`
  padding: 4rem 0 8rem;
  max-width: 120rem;
  margin: 0 auto;
`

function BlogpostRepeater({ children, ...props }) {
  const {
    prismic: {
      allArticles: { blog_posts },
    },
  } = useStaticQuery(graphql`
    query AllBlogPosts {
      prismic {
        allArticles {
          blog_posts: edges {
            post: node {
              _meta {
                uid
                lang
                type
                lastPublicationDate
              }
              summary
              title
              blog_image
              blog_imageSharp {
                childImageSharp {
                  id
                  main: fixed(width: 800) {
                    ...GatsbyImageSharpFixed
                  }
                  mobile: fixed(width: 480) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledBlogpostRepeater {...props}>
      {blog_posts.map(({ post }, i) => {
        return <Blogpost key={post?._meta.uid} index={i} {...post} />
      })}
    </StyledBlogpostRepeater>
  )
}

export default BlogpostRepeater

BlogpostRepeater.defaultProps = {}
BlogpostRepeater.propTypes = {}
