import React from "react"
import PropTypes from "prop-types"
import Content from "components/Content"
import BlogpostRepeater from "components/Blogpost/Repeater"
import Countdown from "components/Countdown"
import ScrollCTA from "components/ScrollCTA"
import Instructions from "components/Instructions"
import Tiles from "components/Tiles"
import Paragraph from "components/Paragraph"
import ParagraphImage from "components/ParagraphImage"
import BrandGroups from "components/BrandElement/Groups"
import Jumbotron from "components/Jumbotron"
import CTA from "components/CTA"
import HowTo from "components/HowTo"
import Login from "app/Login"

function renderModule(module, s, index) {
  switch (module) {
    case "instructions":
      return <Instructions key={`${s.type}_${index}`} {...s.primary.module} />
  }
}

function SliceZone({ ...props }) {
  const { slices } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.type) {
      case "module":
        const { type } = s.primary.module._meta
        return renderModule(type, s, index)
      case "paragraph":
        return <Paragraph key={`${s.type}_${index}`} {...s.primary} />
      case "paragraph___image":
        return <ParagraphImage key={`${s.type}_${index}`} {...s.primary} />
      case "countdown":
        return <Countdown key={`${s.type}_${index}`} {...s.primary} />
      case "scroll_cta":
        return (
          <ScrollCTA
            key={`${s.type}_${index}`}
            {...s.primary}
            items={s.fields}
          />
        )
      case "jumbotron":
        return <Jumbotron key={`${s.type}_${index}`} {...s.primary} />
      case "cta":
        return <CTA key={`${s.type}_${index}`} {...s.primary} />
      case "brand_elements":
        return <BrandGroups key={`${s.type}_${index}`} items={s.fields} />
      case "tiles":
        return (
          <Tiles key={`${s.type}_${index}`} items={s.fields} {...s.primary} />
        )
      case "how_to":
        return <HowTo key={`${s.type}_${index}`} {...s.primary} />
      case "ranking":
        return "Ranking"
      case "blog_posts":
        return <BlogpostRepeater key={`${s.type}_${index}`} />
      case "login":
        return <Login />
      default:
        console.warn(`No support for slice type ${s.type}`)
        return null
    }
  })
  return <>{slice}</>
}

export default SliceZone

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
