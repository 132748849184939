import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { default as LoginForm } from "components/Login"

const StyledLogin = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  padding-bottom: 4rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 40rem;

  `}
`

function Login({ ...props }) {
  return (
    <StyledLogin>
      <LoginForm {...props} />
    </StyledLogin>
  )
}

export default Login

Login.defaultProps = {}
Login.propTypes = {}
