import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const StyledImg = styled(Img)`
  max-width: 100%;
  max-height: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

function Image({ ...props }) {
  function nullHandler(nullables) {
    return Object.keys(props).some((key, i) => {
      return nullables.some((prop) => {
        if (key === prop) {
          const value = Object.values(props)[i]
          if (value == null) {
            return true
          }
        }
      })
    })
  }

  return nullHandler(["fluid", "fixed"]) ? null : <StyledImg {...props} />
}

export default Image
