import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Tile from "components/Tile"
import BrandElement from "components/BrandElement"

const StyledTiles = styled.section`
  position: relative;
`

const StyledTile = styled(Tile)`
  z-index: -2;
  position: relative;
`

function Tiles({
  items,
  tiles_brand_element,
  tiles_brand_element_position,
  ...props
}) {
  return (
    <StyledTiles>
      {items.map((item, index) => (
        <StyledTile
          key={`${item.__typename}_${index}`}
          flipTabletPortrait={index % 2 === 0}
          flipTabletLandscape={index % 2 === 0}
          flipTabletDesktop={index % 2 === 0}
          flipTabletBigDesktop={index % 2 === 0}
          {...item}
        />
      ))}
      {tiles_brand_element && (
        <BrandElement
          {...tiles_brand_element.brand_group[0]}
          direction={tiles_brand_element_position}
          id={tiles_brand_element._meta.id}
          particles={[
            { position: [-272, 0], size: 120 },
            { position: [-150, 80], size: 40 },
          ]}
        />
      )}
    </StyledTiles>
  )
}

export default Tiles

Tiles.defaultProps = {}
Tiles.propTypes = {}
