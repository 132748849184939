import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"

const StyledHowTo = styled.div`
  background: ${(props) => props.theme.colors.sand};
`

function HowTo({ how_to_content_before, how_to_content_after, ...props }) {
  return (
    <StyledHowTo {...props}>
      <Content content={how_to_content_before} />
      <Content content={how_to_content_after} />
    </StyledHowTo>
  )
}

export default HowTo

HowTo.defaultProps = {}
HowTo.propTypes = {
  children: PropTypes.node.isRequired,
}
