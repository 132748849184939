import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import InputButton from "components/InputButton"
import Button from "components/Button"
import Input from "components/Input"
import BrandElement from "components/BrandElement"

const StyledCTA = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.sand};
  text-align: center;
  margin: 0;
  padding: 8rem 2rem;
`

const StyledContent = styled.div`
  position: relative;
  z-index: 1;

  h4 {
    font-family: ${(props) => props.theme.fontStacks.subheading};
    font-size: 150%;
    margin-bottom: 0.5rem;
  }

  > * {
    margin: 0;
  }
`

const StyledBrandElement = styled(BrandElement)`
  z-index: 0;
`

const StyledInputButton = styled(InputButton)`
  max-width: 70rem;
  margin: auto;
  margin-top: 2rem;
`

function CTA({
  cta_content,
  cta_brand_element,
  cta_brand_element_position,
  ...props
}) {
  return (
    <StyledCTA {...props}>
      <StyledContent>
        <Content content={cta_content} />
        <StyledInputButton
          button={
            <Button
              href="mailto:hej@danmarksopsamling.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Send os en email
            </Button>
          }
        />
      </StyledContent>
      {cta_brand_element && (
        <StyledBrandElement
          {...cta_brand_element.brand_group[0]}
          direction={cta_brand_element_position}
          id={cta_brand_element._meta.id}
          particles={[
            { position: [-200, 0], size: 120 },
            { position: [-92, 70], size: 40 },
            { position: [-130, -130], size: 20 },
          ]}
        />
      )}
    </StyledCTA>
  )
}

export default CTA

CTA.defaultProps = {}
CTA.propTypes = {}
