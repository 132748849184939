import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Paragraph from "components/Paragraph"
import Content from "components/Content"
import BrandElement from "components/BrandElement"

const StyledWrapper = styled.div`
  position: relative;
  z-index: 200;
`

const StyledJumbotron = styled.div`
  text-align: center;
  margin: auto;
  padding: 6rem 4rem 4rem;
  max-width: ${(props) => (!!props.hasContent ? `75rem` : `90rem`)};

  h2 {
    margin-top: 0;
    font-size: 2.4rem;
    line-height: 1.15;
    font-family: ${(props) => props.theme.fontStacks.heading};
    color: ${(props) => props.theme.colors.coral};
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    padding: 12rem 4rem 12rem;
    h2 {
      font-size: 3.2rem;
      max-width: none;
      font-size: 4.5rem;
      margin-bottom: 0;
    }
  `}
`

function Jumbotron({
  jumbotron_title,
  jumbotron_content,
  jumbotron_link,
  jumbotron_brand_element,
  jumbotron_brand_element_position,
  ...props
}) {
  return (
    <StyledWrapper>
      <StyledJumbotron
        id="introduction"
        hasContent={!!jumbotron_content}
        {...props}
      >
        {jumbotron_brand_element && (
          <BrandElement
            {...jumbotron_brand_element.brand_group[0]}
            direction={jumbotron_brand_element_position}
            id={jumbotron_brand_element._meta.id}
            particles={[
              { position: [10, 0], size: 120 },
              { position: [110, -80], size: 50 },
              { position: [-90, -10], size: 25 },
            ]}
          />
        )}
        <Content content={jumbotron_title} />
        {jumbotron_content && <Paragraph content={jumbotron_content} />}
      </StyledJumbotron>
    </StyledWrapper>
  )
}

export default Jumbotron

Jumbotron.defaultProps = {}
Jumbotron.propTypes = {}
