import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledSubheading = styled.span`
  font-family: ${(props) => props.theme.fontStacks.subheading};
  letter-spacing: 0.1rem;
`

function Subheading({ children, ...props }) {
  return <StyledSubheading {...props}>{children}</StyledSubheading>
}

export default Subheading

Subheading.defaultProps = {}
Subheading.propTypes = {
  children: PropTypes.node.isRequired,
}
