import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { animated, useSpring } from "react-spring"
import { formatFloat } from "@mostlycode/utils"

const StyledCounter = styled(animated.span)``

function Counter({ start, end, ...props }) {
  const counterProps = useSpring({
    from: {
      number: start,
    },
    to: {
      number: end,
    },
    config: {
      friction: 80,
    },
  })
  return (
    <StyledCounter>
      {counterProps.number.interpolate((x) =>
        formatFloat(x.toFixed(0), "da-DK")
      )}
    </StyledCounter>
  )
}

export default Counter

Counter.defaultProps = {
  start: 100000,
  end: 0,
}

Counter.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
}
