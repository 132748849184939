import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { Icon } from "@mostlycode/ui"
import BrandGroups from "components/BrandElement/Groups"

const StyledScrollCTA = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 2000;

  div {
    background: ${(props) => props.theme.colors.white};
    text-align: center;
    font-size: 115%;
    padding-bottom: 1rem;
    color: ${(props) => props.theme.colors.coral};
    position: relative;
    ${(props) => props.theme.media.tablet_landscape_up`
      padding-bottom: 1.5rem;
      background: transparent;
    `}

    h3 {
      margin: 0;
      padding-bottom: 1rem;
      font-family: ${(props) => props.theme.fontStacks.subheading};
      font-size: 90%;
      ${(props) => props.theme.media.tablet_landscape_up`
        font-size: 115%;
        padding-bottom: 1.5rem;
      `}
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &::before {
    content: "";
    display: block;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      ${(props) => props.theme.colors.white}
    );

    height: 8rem;
    width: 100%;

    ${(props) => props.theme.media.tablet_landscape_up`
      background-image: none;
    `}
  }
`

const Filler = styled.div`
  width: 100%;
  height: 5rem;
`

const floating = keyframes`
  to {
    transform: translateY(0rem);
  }
`

const AnimatedIcon = styled(Icon)`
  position: relative;
  transform: translateY(-0.5rem);
  animation: 0.4s ${floating} infinite alternate cubic-bezier(0, 0, 1, 0);
`

const BrandGroupWrapper = styled.div`
  position: relative;
  z-index: 200;
  bottom: -1rem;
`

function ScrollCTA({ scroll_content, items, ...props }) {
  return (
    <>
      <Filler />
      <StyledScrollCTA {...props}>
        <div>
          <h3>{scroll_content}</h3>
          <AnimatedIcon icon="chevron-down" size="lg" />
          <a href="#introduction"></a>
        </div>
      </StyledScrollCTA>
      <BrandGroupWrapper>
        <BrandGroups items={items} />
      </BrandGroupWrapper>
    </>
  )
}

export default ScrollCTA

ScrollCTA.defaultProps = {}
ScrollCTA.propTypes = {}
