import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { linkResolver } from "utils/linkResolver"
import ResponsiveImage from "components/ResponsiveImage"
import Content from "components/Content"
import Tile from "components/Tile"

const StyledBlogpost = styled(Tile)`
  border-radius: 3px;
  overflow: hidden;
  margin: 4rem 2rem 0;
  filter: drop-shadow(0 10px 20px rgba(50, 50, 93, 0.5));

  & + & {
    margin-top: 4rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

function Blogpost({
  children,
  _meta,
  blog_imageSharp,
  blog_image,
  title,
  summary,
  index,
  ...props
}) {
  const tile = {
    imageSharp: blog_imageSharp,
    image: blog_image,
    header: title,
    button_text: "Læs artiklen",
    link: {
      _meta: _meta,
    },
  }
  return (
    <StyledBlogpost
      tile={tile}
      flipTabletPortrait={index % 2 === 0}
      flipTabletLandscape={index % 2 === 0}
      flipTabletDesktop={index % 2 === 0}
      flipTabletBigDesktop={index % 2 === 0}
      isWrapped
    >
      {summary}
    </StyledBlogpost>
  )
  /*   return (
    <StyledBlogpost {...props}>
      <ResponsiveImage img={blog_imageSharp || blog_image} />
      <Link to={url} className="tile_link" />
    </StyledBlogpost>
  ) */
}

export default Blogpost

Blogpost.defaultProps = {}
Blogpost.propTypes = {
  children: PropTypes.node,
}
