import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { lighten, rgba } from "polished"
import { graphql, Link } from "gatsby"
import { GridParagraph } from "@mostlycode/ui"
import { Elements } from "prismic-reactjs"
import Responsive from "components/Responsive"
import ResponsiveImage from "components/ResponsiveImage"
import RenderShape from "components/BrandElement/RenderShape"
import Button from "components/Button"
import Content from "components/Content"
import Paragraph from "components/Paragraph"
import Subheading from "components/Subheading"
import { linkResolver } from "utils/linkResolver"

function getTheme(tileTheme, colors, svg) {
  switch (tileTheme) {
    case "Blue":
      return svg
        ? `fill: ${colors.blue};`
        : `
        background: ${colors.blue};
        color: ${colors.white};

        h2 {
          color: ${colors.coral};
        }
      `
    case "Coral":
      return svg
        ? `fill: ${colors.coral};`
        : `
        background: ${colors.coral};
        color: ${colors.white};

        h2 {
          color: ${colors.blue};
        }

        * {
          &::selection {
            background: ${lighten(0.025, rgba(colors.blue, 0.85))};
          }

          img::selection {
            background: ${lighten(0.025, rgba(colors.blue, 0.7))};
          }
        }
      `
    case "Sand":
      return svg
        ? `fill: ${colors.sand};`
        : `
        background: ${colors.sand};
        color: ${colors.blue};
        h2 {
          color: ${colors.coral};
        }
      `
    case "White":
    default:
      return svg
        ? `fill: ${colors.white};`
        : `
        background: ${colors.white};
        color: ${colors.blue};
        h2 {
          color: ${colors.coral};
        }
      `
  }
}

const StyledTile = styled(GridParagraph)`
  ${(props) => getTheme(props.tileTheme, props.theme.colors)};
  ${(props) => props.theme.media.tablet_portrait_up`
    max-height: 50rem;
  `}
  margin-bottom: -0.25rem;
`

const StyledTileImage = styled.div`
  grid-area: image;
  position: relative;
  overflow: hidden;

  ${(props) => getTheme(props.tileTheme, props.theme.colors)};

  svg {
    ${(props) => getTheme(props.tileTheme, props.theme.colors, true)};
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    max-height: 50rem;
  `}
`

const StyledRenderShape = styled(RenderShape)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: -6px;
  z-index: 20;
  width: auto;
  height: 100%;

  ${(props) => props.theme.media.tablet_portrait_up`
    bottom: 0;
    width: auto;
    height: 100%;
    transform: none;
    ${props.flip ? `right: auto;` : `left: auto;`}
  `}
`

const StyledTileContent = styled.article`
  padding: 5rem 2rem;
  grid-area: paragraph;
  margin-top: -8rem;
  position: relative;
  z-index: 100;

  h2 {
    margin-top: 0;
    font-size: 3.2rem;
    line-height: 1.15;
    max-width: 80%;
    font-family: ${(props) => props.theme.fontStacks.heading};
  }

  a {
    margin-top: 2rem;
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    margin: auto 0;
    ${
      props.isWrapped
        ? `
          ${StyledTile}:nth-child(even) & {
            padding: 2rem 3vw 2rem 2rem;
          }

          ${StyledTile}:nth-child(odd) & {
            padding: 2rem 2rem 2rem 3vw;
          }`
        : `
          ${StyledTile}:nth-child(even) & {
            padding: 2rem 10vw 2rem 2rem;
          }

          ${StyledTile}:nth-child(odd) & {
            padding: 2rem 2rem 2rem 10vw;
          }
        `
    }


    h2 {
      max-width: none;
      font-size: 4.5rem;
      margin-bottom: .5em;
    }

    p {
      font-size: 1.7rem;
    }
  `}
`

const StyledPreHeader = styled(Subheading)`
  font-family: ${(props) => props.theme.fontStacks.subheading};
`

const htmlSerializer = (type, children) => {
  switch (type) {
    case Elements.heading3:
      return <StyledPreHeader key="preheader">{children}</StyledPreHeader>
    default:
      return null
  }
}

function Tile({ tile, tile_theme, isWrapped, children, ...props }) {
  const gridPhone = `"image" "paragraph"`
  const gridTabletPortrait = `"image image paragraph paragraph"`
  const gridTabletLandscape = `"image image image image paragraph paragraph paragraph"`
  const tileLink = tile?.link?._meta ? linkResolver(tile?.link?._meta) : null
  return (
    <StyledTile
      gridPhone={gridPhone}
      gridTabletPortrait={gridTabletPortrait}
      gridTabletLandscape={gridTabletLandscape}
      useFractions
      tileTheme={tile_theme}
      {...props}
    >
      <StyledTileImage tileTheme={tile_theme}>
        <ResponsiveImage
          img={tile?.imageSharp || tile?.image}
          alt={tile.image.alt}
        />
        <Responsive
          phone={
            <StyledRenderShape
              shape="wave-bottom-right"
              flip={props.flipTabletPortrait}
            />
          }
          tabletPortrait={
            props.flipTabletPortrait ? (
              <StyledRenderShape
                shape="coral-wave-bottom-left"
                flip={props.flipTabletPortrait}
              />
            ) : (
              <StyledRenderShape
                shape="coral-wave-top-right"
                flip={props.flipTabletPortrait}
              />
            )
          }
        />
      </StyledTileImage>
      <StyledTileContent isWrapped={isWrapped}>
        {tile?.preheader && (
          <Content
            content={tile.preheader}
            serializer={(type, element, content, children) =>
              htmlSerializer(type, children, null, null)
            }
          />
        )}
        {tile?.header && <Content content={tile.header} />}
        {tile?.summary && <Content content={tile.summary} />}
        {children && children}
        {tileLink && (
          <div>
            <Button
              href={tileLink}
              variant={tile_theme === "Coral" ? "primary" : "default"}
            >
              {tile?.button_text}
            </Button>
          </div>
        )}
      </StyledTileContent>
    </StyledTile>
  )
}

export default Tile

Tile.defaultProps = {}
Tile.propTypes = {
  tile_theme: PropTypes.oneOf(["Blue", "Coral", "Sand", "White"]),
}
