import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { default as DefaultFormField } from "@mostlycode/ui/dist/FormField"

const StyledFormField = styled(DefaultFormField)`
  label {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.8rem;
  }
`

function FormField({ children, ...props }) {
  return <StyledFormField {...props}>{children}</StyledFormField>
}

export default FormField

FormField.defaultProps = {}
FormField.propTypes = {
  children: PropTypes.node,
}
