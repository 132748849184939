import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import { SiteContext } from "context/SiteContext"

const StyledLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: ${(props) => props.theme.colors.white};
  transition: all ease-out 0.2s;

  ${(props) =>
    props.isLoading
      ? `
        transform: scale3d(1, 1, 1);
        opacity: 1;
      `
      : `
          transform: scale3d(0.9, 0.9, 0.9);
          opacity: 0;
          pointer-events: none;
        `}
`

const StyledLoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
    fill: ${(props) => props.theme.colors.coral};
    animation: pulse 2s linear infinite forwards;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`

function Loading({ ...props }) {
  const {
    state: { loading },
  } = useContext(SiteContext)
  return (
    <StyledLoading isLoading={loading} {...props}>
      <StyledLoadingContent>
        <Icon size="auto" icon="logo-icon" />
        <span>Loading...</span>
      </StyledLoadingContent>
    </StyledLoading>
  )
}

export default Loading

Loading.defaultProps = {}
Loading.propTypes = {}
