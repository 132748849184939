import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ViewportContext } from "context"
import { isViewport } from "helpers"
import Image from "components/Image"

function ResponsiveImage({ img, alt, ...props }) {
  const viewport = useContext(ViewportContext)
  let imgSrc

  if (img.hasOwnProperty("childImageSharp")) {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.childImageSharp.mobile
      : img.childImageSharp.main
    return isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
      <Image fixed={imgSrc} alt={alt} {...props} />
    ) : (
      <Image fixed={imgSrc} alt={alt} style={{ display: "block" }} {...props} />
    )
  } else {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.mobile.url
      : img.url
    return <img src={imgSrc} alt={alt} />
  }
}

export default ResponsiveImage

ResponsiveImage.defaultProps = {
  alt: "",
}
ResponsiveImage.propTypes = {
  img: PropTypes.object,
  alt: PropTypes.string,
}
