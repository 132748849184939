import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Elements } from "prismic-reactjs"
import styled from "styled-components"
import Content from "components/Content"
import Counter from "./Counter"
import Duration from "components/Duration"

const StyledCountdown = styled.section`
  padding: 4rem 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    max-height: 70vh;
    min-height: 60vh;
    padding: 2.5vw 10vw 2.5vw;
    bottom: 4rem;
  `}
`

const StyledCountdownContent = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  z-index: 200;
  position: relative;
  width: 75%;
  margin: auto;

  p {
    font-size: 2.1rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 50%;
  `}
`

const StyledCoral = styled.span`
  color: ${(props) => props.theme.colors.coral};
  font-family: ${(props) => props.theme.fontStacks.heading};
  text-transform: none;
  display: inline-block;
  width: 200%;
  transform: translateX(-25%);
  font-size: 215%;
  line-height: 5rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 260%;
    line-height: 10rem;
    width: 200%;
    transform: translateX(-25%);
  `}
`

const StyledHeading1 = styled.h1`
  font-size: 4.8rem;
  line-height: 1.2;
  margin: 1rem 0;
  color: ${(props) => props.theme.colors.coral};

  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 10.9rem;
  `}
`

// const StyledHeading2 = styled.h2`
//   font-size: 2.1rem;
//   ${(props) => props.theme.media.tablet_landscape_up`
//     font-size: 2.6rem;
//   `}
// `

const StyledHeading3 = styled.h3`
  margin: 0;
  line-height: 1.5;
  font-size: 2.2rem;
  letter-spacing: 0.1rem;
  font-family: ${(props) => props.theme.fontStacks.subheading};
  text-transform: uppercase;

  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 3.5rem;
  `}
`

const htmlSerializer = (
  type,
  children,
  target,
  target_unit,
  remaining,
  countdown_start,
  countdown_end
) => {
  switch (type) {
    case Elements.strong:
      return (
        <StyledCoral key="duration">
          <Duration start={countdown_start} end={countdown_end} />
        </StyledCoral>
      )
    case Elements.em:
      return (
        <StyledCoral key="counter">
          <Counter
            start={remaining <= 0 ? 0 : target}
            end={remaining <= 0 ? Math.abs(remaining) : remaining}
          />{" "}
          {target_unit}
        </StyledCoral>
      )
    case Elements.heading1:
      return <StyledHeading1 key="h1">{children}</StyledHeading1>
    case Elements.heading3:
      return <StyledHeading3 key="h3">{children}</StyledHeading3>
    default:
      return null
  }
}

function Countdown({ countdown, ...props }) {
  const {
    target,
    target_unit,
    target_not_reached_text,
    target_reached_text,
    countdown_start,
    countdown_end,
  } = countdown

  const data = useStaticQuery(graphql`
    query TotalCollectedQuery {
      ltowrTotals {
        body {
          total_collected
        }
      }
    }
  `)

  const {
    ltowrTotals: {
      body: { total_collected },
    },
  } = data

  const [remaining, setRemaining] = useState(target - total_collected)

  return (
    <StyledCountdown>
      <StyledCountdownContent>
        <Content
          content={
            remaining <= 0 ? target_reached_text : target_not_reached_text
          }
          serializer={(type, element, content, children) =>
            htmlSerializer(
              type,
              children,
              target,
              target_unit,
              remaining,
              countdown_start,
              countdown_end
            )
          }
        />
      </StyledCountdownContent>
    </StyledCountdown>
  )
}

export default Countdown

Countdown.defaultProps = {}
Countdown.propTypes = {}

export const query = graphql`
  fragment CountdownFragment on PRISMIC_Countdown {
    countdown_start
    countdown_end
    target
    target_unit
    target_not_reached_text
    target_reached_text
  }
`
