import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"

const StyledInstructions = styled.section`
  padding: 4rem 2rem;
  background: ${(props) => props.theme.colors.sand};
  ${(props) => props.theme.media.tablet_portrait_up`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 4rem 5vw;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 8rem 10vw;
  `}
`

const StyledStep = styled.article`
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  text-align: center;

  p {
    max-width: 26rem;
  }

  p:first-child {
    font-family: ${(props) => props.theme.fontStacks.subheading};
    letter-spacing: 0.1rem;
    font-size: 2rem;
    max-width: 20rem;
  }
`

const StyledStepIcon = styled.figure`
  margin: 1rem 0;
  position: relative;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-family: ${(props) => props.theme.fontStacks.subheading};
    color: ${(props) => props.theme.colors.white};
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
    transform: translate3d(-1.5rem, 0.5rem, 0);
  }

  div {
    display: flex;
    align-items: center;
    width: 15.2rem;
    height: 15.2rem;
    padding: 2rem;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 50%;

    img {
      display: block;
      max-height: 100%;
    }
  }
`

function Instructions({ ...props }) {
  const { steps } = props
  return (
    <StyledInstructions id="instructions">
      {steps.map((step, i) => {
        const { step_icon, after_icon, before_icon } = step
        return (
          <StyledStep key={`step_${i}`}>
            <Content content={before_icon} />
            <StyledStepIcon>
              <span>{`${i + 1}.`}</span>
              <div>
                <img src={step_icon.url} alt="" />
              </div>
            </StyledStepIcon>

            <Content content={after_icon} />
          </StyledStep>
        )
      })}
    </StyledInstructions>
  )
}

export default Instructions

Instructions.defaultProps = {}
Instructions.propTypes = {}
